<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" max-width="1200px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                        color="primary"
                        dark
                        class="ml-auto ma-3 mr-1"
                        @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                        v-if="hasPermission('sell_sheets.create')"
                        color="primary"
                        dark
                        class="ma-3 ml-0"
                        v-on="on"
                    >
                      <i class="flaticon2-plus text-white"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id"
                    >Edit {{ editedItem.title }}</span
                    >
                    <span v-else>Create Sell Sheet</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Details</v-tab>
                      <v-tab>Media</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                                ref="form"
                                v-model="validationRules.valid"
                                lazy-validation
                            >
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.series_title"
                                      :counter="100"
                                      label="Series Title*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.sheet_title"
                                      :counter="100"
                                      label="Title*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.isbn_digital"
                                      :counter="100"
                                      label="ISBN (Digital)*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.isbn_hc"
                                      :counter="100"
                                      label="ISBN (HC)*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="date"
                                      v-model="editedItem.release_date"
                                      :counter="100"
                                      label="Release Date*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="date"
                                      v-model="editedItem.publication_date"
                                      :counter="100"
                                      label="Publication Date*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="number"
                                      v-model="editedItem.page_count"
                                      :counter="100"
                                      label="Page Count*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="number"
                                      v-model="editedItem.price_digital"
                                      :counter="100"
                                      label="Price (Digital)*"
                                      required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="number"
                                      v-model="editedItem.price_hc"
                                      :counter="100"
                                      label="Price (HC)*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.genres"
                                      :counter="100"
                                      label="Genres*"
                                      required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_summary"
                                      :counter="150"
                                      label="Book Summary*"
                                      required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <label class="h4"> Book Description </label>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_description_title"
                                      :counter="115"
                                      label="Book Description Title*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_description_1"
                                      :counter="115"
                                      label="Description Para 1*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_description_2"
                                      :counter="115"
                                      label="Description Para 2*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_description_3"
                                      :counter="115"
                                      label="Description Para 3"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_description_4"
                                      :counter="115"
                                      label="Description Para 4"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_description_5"
                                      :counter="115"
                                      label="Description Para 5"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.book_description_6"
                                      :counter="115"
                                      label="Description Para 6"
                                      required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <label class="h4"> Key Selling Points </label>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.key_selling_points_1"
                                      :counter="300"
                                      label="Point 1*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.key_selling_points_2"
                                      :counter="300"
                                      label="Point 2*"
                                      required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <label class="h4"> Marketing/Publicity </label>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.marketing_1"
                                      :counter="100"
                                      label="Point 1*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.marketing_2"
                                      :counter="100"
                                      label="Point 2*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.marketing_3"
                                      :counter="100"
                                      label="Point 3"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.marketing_4"
                                      :counter="100"
                                      label="Point 4"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.marketing_5"
                                      :counter="100"
                                      label="Point 5"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.marketing_6"
                                      :counter="100"
                                      label="Point 6"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.marketing_7"
                                      :counter="100"
                                      label="Point 7"
                                      required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <label class="h4"> Author </label>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      type="text"
                                      v-model="editedItem.about_the_author"
                                      :counter="500"
                                      label="About The Author*"
                                      required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <h4 class="mt-5">Main image</h4>
                        <ModuleMedia
                            moduleType="image"
                            moduleOf="ss_main_image"
                            moduleMultiple="false"
                            :moduleImages="moduleImages"
                            :moduleImagesOld="moduleImagesOld"
                        ></ModuleMedia>
                        <h4>Cover image</h4>
                        <ModuleMedia
                            moduleType="image"
                            moduleOf="ss_cover_image"
                            moduleMultiple="false"
                            :moduleImages="moduleCImages"
                            :moduleImagesOld="moduleCImagesOld"
                        ></ModuleMedia>
                        <h4>Author image</h4>
                        <ModuleMedia
                            moduleType="image"
                            moduleOf="ss_author_image"
                            moduleMultiple="false"
                            :moduleImages="moduleAImages"
                            :moduleImagesOld="moduleAImagesOld"
                        ></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        :disabled="!validationRules.valid"
                        color="blue darken-1"
                        text
                        @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                        v-if="errors != undefined && errors.length >= 0"
                        role="alert"
                        v-bind:class="{ show: errors.length }"
                        class="alert fade alert-danger"
                    >
                      <div
                          class="alert-text"
                          v-for="(error, i) in errors"
                          :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Sell Sheets ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0"
              >
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ moment(item.created_at).fromNow() }}
                  </div>
                </template>
                <template v-slot:item.author_image_public_url="{ item }">
                  <img :src="item.author_image_public_url" style="width:50px;height:50px;"
                       v-if="item.author_image_public_url"
                       alt="">
                </template>

                <template v-slot:item.isbn_digital="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.isbn_digital"></span>
                    <br>/
                    <br>
                    <span v-html="item.isbn_hc"></span>
                  </div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="hasPermission('sell_sheets.edit')"
                            color="green"
                            dark
                            @click="downloadFile(item)"
                            class="mr-2"
                            v-on="on"
                        >
                          <v-icon dark small>fas fa-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download PDF</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="hasPermission('sell_sheets.edit')"
                            color="primary"
                            @click="showEditDialog(item)"
                            dark
                            class="mr-2"
                            v-on="on"
                        >
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="hasPermission('sell_sheets.delete')"
                            color="red"
                            dark
                            @click="deleteItem(item)"
                            class="mr-2"
                            v-on="on"
                        >
                          <v-icon dark small>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  SELL_SHEETS_LIST,
  CREATE_SELL_SHEET,
  UPDATE_SELL_SHEET,
  CLEAR_SELL_SHEET_ERRORS,
  DELETE_SELL_SHEET,
  GET_SELL_SHEET_DETAILS,
} from "@/core/services/store/sellSheets.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {bus} from "@/main";

export default {
  name: "sell_sheets",
  components: {
    ModuleMedia
  },
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        mustSort: true,
        multiSort: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      moduleImagesOld: [],
      moduleCImages: [],
      moduleCImagesOld: [],
      moduleAImages: [],
      moduleAImagesOld: [],
      headerindex: "",
      headers: [
        {
          text: "Author",
          value: "author_image_public_url",
          name: "author_image_public_url",
          filterable: true,
          sortable: false,
        },
        {
          text: "Sheet Title",
          value: "sheet_title",
          name: "sheet_title",
          filterable: true,
          sortable: false,
        },
        {
          text: "Series Title",
          value: "series_title",
          name: "series_title",
          filterable: true,
          sortable: false,
        },
        {
          text: "ISBN Digital/HC",
          value: "isbn_digital",
          name: "isbn_digital",
          filterable: true,
          sortable: false,
        },
        {
          text: "Release Date",
          value: "release_date_formatted",
          name: "release_date_formatted",
          filterable: true,
          sortable: false,
        },
        {
          text: "Publication Date",
          value: "publication_date_formatted",
          name: "publication_date_formatted",
          filterable: true,
          sortable: false,
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        },
        {
          text: "Actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
        },
      ],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v =>
              (v && v.length <= 50) ||
              "Must be less than or equal to 200 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        descriptionRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 6) || "Must be at least 6 characters"
        ],
        priceRules: [
          v => !!v || "This field is required",
          v =>
              (v && v.length <= 10) ||
              "Must be less than or equal to 10 characters",
          v => (v && v.length >= 1) || "Must be at least 1 characters"
        ],
        asinRules: [
          v => !!v || "This field is required",
          v =>
              (v && v.length <= 30) ||
              "Must be less than or equal to 30 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        isbnRules: [
          v => !!v || "This field is required",
          v =>
              (v && v.length <= 30) ||
              "Must be less than or equal to 30 characters",
          v => (v && v.length >= 13) || "Must be at least 13 characters"
        ],
        selectedRules: [v => !!v || "This field is required"]
      },
      items: [],
      dialog: false,
      editedItem: {},
      ques: [
        {value: "0", title: "No"},
        {value: "1", title: "Yes"}
      ],
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_SELL_SHEET_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
      if (!val) this.moduleCImages = [];
      if (!val) this.moduleCImagesOld = [];
      if (!val) this.moduleAImages = [];
      if (!val) this.moduleAImagesOld = [];
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sell Sheets Management", route: "sell-sheets"},
      {title: "Sell Sheets"}
    ]);
    bus.$on("moduleImagesUploaded", moduleImages => {
      if (moduleImages.type === "ss_main_image") {
        this.moduleImages = moduleImages.arr;
      } else if (moduleImages.type === "ss_cover_image") {
        this.moduleCImages = moduleImages.arr;
      } else {
        this.moduleAImages = moduleImages.arr;
      }
    });
    bus.$on("moduleImagesUploadedOld", moduleImagesOld => {
      if (moduleImagesOld.type === "ss_main_image") {
        this.moduleImagesOld = moduleImagesOld.arr;
      } else if (moduleImagesOld.type === "ss_cover_image") {
        this.moduleCImagesOld = moduleImagesOld.arr;
      } else {
        this.moduleAImagesOld = moduleImagesOld.arr;
      }
    });
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
            .map(key => {
              return (
                  encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 7 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store
            .dispatch(SELL_SHEETS_LIST, {q: query, columns: this.columns})
            .then(res => {
              this.draw++;
              let items = res.records.data;
              let total = res.records.recordsFiltered;
              this.loading = !this.loading;
              this.isLoaded = !this.isLoaded;
              resolve({
                items,
                total
              });
            });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_SELL_SHEET_DETAILS, item.id).then(data => {
        let mImages = [];
        let cImages = [];
        let aImages = [];
        this.editedItem = data.records || {};
        if (data.records.main_image_public_url !== null) {
          mImages.push(data.records);
        }
        if (data.records.cover_image_public_url !== null) {
          cImages.push(data.records);
        }
        if (data.records.author_image_public_url !== null) {
          aImages.push(data.records);
        }
        this.moduleImagesOld = mImages;
        this.moduleCImagesOld = cImages;
        this.moduleAImagesOld = aImages;
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      if (this.moduleImages.length > 0 || this.moduleCImages.length > 0 || this.moduleAImages.length > 0) {
        let mFiles = [];
        let cFiles = [];
        let aFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        cFiles = this.moduleCImages;
        aFiles = this.moduleAImages;
        let fFiles = mFiles.concat(cFiles).concat(aFiles);
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
                .dispatch("delete_uploaded_image", {
                  files: fFiles,
                  type: "modal"
                })
                .then(() => {
                  that.moduleImages = [];
                  that.moduleCImages = [];
                  that.moduleAImages = [];
                  that.dialog = false;
                  bus.$emit("moduleImagesDeletedByModal");
                });
          }
        });
      } else {
        this.dialog = !this.dialog;
        // bus.$emit('moduleModalClosed');
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_SELL_SHEET;
        let id = item.id;
        this.$set(item, "main_image", this.moduleImages);
        this.$set(item, "cover_image", this.moduleCImages);
        this.$set(item, "author_image", this.moduleAImages);
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_SELL_SHEET;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_SELL_SHEET_ERRORS);
        // save the record
        this.$store.dispatch(method, {slug: id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleImagesDeletedByModal");
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.sheet_title + "'?",
        clr: "red",
        callback: function () {
          let id = item.id;
          this.$store
              .dispatch(DELETE_SELL_SHEET, {
                sellsheet_id: id
              })
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                });
              });
        }
      });
    },
    downloadFile(item) {
      window.open(`${this.axios.defaults.baseURL}/api/sell_sheets/${item.id}/pdf/download`, "", "width=400,height=600");
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: (state) => state.SellSheets.errors,
    })
  }
};
</script>
